<template>
  <base-section id="news-detail">
    <v-container class="mt-n15">
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <template>
          <v-card
            class="mx-auto"
            v-for="data in posts"
            :key="data.web_news_id"
            flat
            width="700px"
          >
            <v-img
              :src="data.web_news_src_url"
              :alt="$route.params.url"
              contain
            ></v-img>
            <div class="headline my-5 primary--text"> {{ title }} </div>
            <div class="body-2 fourth--text">{{ FormatDateFullOnlyDate(data.web_news_cdate) }}</div>
            <v-alert
              border="top"
              colored-border
              color="primary"
              class="body-1 text-justify"
            >
            <div itemscope v-html="data.web_news_detail"></div>
            </v-alert>
            <v-btn color="primary" outlined>{{ data.web_news_category }}</v-btn>
            <v-divider class="mt-2"></v-divider>
            <v-card-actions>
              <v-speed-dial
              v-model="share"
              :top="true"
              :left="true"
              direction="bottom"
              transition="slide-y-reverse-transition"
              style="position: fixed; top: 164px; left: -1px;"
              >
              <template v-slot:activator>
                <v-btn
                  v-model="share"
                  color="fourth"
                  outlined
                >
                  <v-icon v-if="share">mdi-close</v-icon>
                  <v-icon v-else>mdi-share</v-icon>
                  <span class="hidden-xs-only">Bagikan</span>
                </v-btn>
              </template>
              <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :hashtags="sharing.hashtags"
              >
                <v-btn fab color="fourth"><v-icon :color="network.color" x-large>{{ network.icon }}</v-icon></v-btn>
              </ShareNetwork>
            </v-speed-dial>
            </v-card-actions>
          </v-card>
        </template>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNewsDetail',
    data: () => ({
          title: '',
          share: false,
          posts: [],
          sharing: {
            url: '',
            title: '',
            hashtags: 'takafulumum,asuransisyariah',
          },
          networks: [
            { network: 'email', name: 'Email', icon: 'mdi-email', color: 'white' },
            { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: 'white' },
            { network: 'telegram', name: 'Telegram', icon: 'mdi-send', color: 'white' },
            { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: 'white' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: 'white' },
          ]
      }),
    created () {
      this.List()
    },
    watch: {
        "$route.params.url"(val) {
          console.log(val)
          this.List();
        },
        title: function (val, old) {
            console.log(old)
            document.title     = val
            this.sharing.title = this.$functions.AppURL() +""+ this.$router.currentRoute.path
        }
    },
    methods: {
      List () {
        this.loading  = true
        let title = this.$route.params.url
        let formdata = {
              web_news_id: title
        }
        let param      = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiWebNewsList'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
        })
        .then(response => {
            this.posts = response.data
            this.title = this.posts[0].web_news_name
            this.loading  = false
        })
        .catch(e => {
            alert(e)
            this.loading  = false
        })
      },
      FormatDateFullOnlyDate (date) {
        return this.$functions.FormatDateFullOnlyDate(date)
      },
      Detail (url) {
        this.$router.push({path: 'berita-detail', query: { url: url }})
      },
      Share () {
        window.open('https://wa.me/?text='+encodeURIComponent(this.$route.params.url))
      }
    }
  }
</script>
